import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>
      An EV Charging revolution is coming soon. <br />
      Stay Tuned.
    </h1>
  </Layout>
);

export default IndexPage;
